<div class="side-popup">
  <div class="side-popup-header">
    <h1>{{!this.updating ? 'Activate' : 'Update'}} Support Plan</h1>

    <div class="side-popup-close" (click)="close()">
      <i class="icon-cancel"></i>
    </div>
  </div>

  <div class="side-popup-content form-content">
    <form [formGroup]="supportPlanForm" class="w-100">
      <div class="form-group">
        <label for="presetId" required>Support Plan <span class="ng-star-inserted">*</span></label>
        <ng-select [clearable]="false" [searchable]="false" formControlName="presetId">
          <ng-container *ngFor="let plan of availablePlans">
            <ng-option [value]="plan.id">
              {{ plan.name }}
            </ng-option>
          </ng-container>
        </ng-select>
        <app-form-errors [control]="supportPlanForm.controls['presetId']" label="Price per month"></app-form-errors>
      </div>
      <div class="form-group">
        <label for="price" required>Price per month <span class="ng-star-inserted">*</span></label>
        <input type="number" placeholder="Enter price per month in CHF" class="form-control" id="price" formControlName="price">
        <app-form-errors [control]="supportPlanForm.controls['price']" label="Price per month"></app-form-errors>
      </div>
      <p class="require-message"><span class="app-color">*</span> Required fields</p>
    </form>
  </div>

  <div class="side-popup-footer">
    <button type="button" class="btn btn-link link" (click)="close()">Cancel</button>
    <app-submit-btn [loading]="loading$ | async" (btnClick)="submitPlan()">{{!this.updating ? 'Activate': 'Update'}}</app-submit-btn>
  </div>
</div>
