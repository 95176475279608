<div class="side-popup slick-footer">
  <div class="side-popup-header">
    <h1>{{ editMode ? 'Edit User' : 'Create User' }}</h1>
    <div class="side-popup-close" (click)="close()">
      <i class="icon-cancel"></i>
    </div>
  </div>

  <div class="side-popup-content" *ngIf="elements">

    <!-- @todo to Shared? -->
    <ng-container *ngIf="editMode && !isAdmin">
      <div class="profile-fallback" myflowDnD (filesChangeEmiter)="open($event)"
          [allowedExtensions]="['jpg', 'jpeg', 'png', 'gif']" (dragover)="draggable = true"
          (drop)="draggable = false">
        <img [src]="avatar | domSanitizer" class="profile-picture" *ngIf="avatar">
        <div class="actions" *ngIf="!draggable">
          <button class="btn btn-primary btn-sm edit" (click)="file.click()">
            <i class="icofont icofont-ui-edit"></i>
          </button>
          <button *ngIf="avatar" class="btn btn-primary btn-sm delete" (click)="deleteAvatar()">
            <i class="icofont icofont-ui-delete"></i>
          </button>
          <input type="file" #file style="display: none" (change)="open()"/>
        </div>
      </div>
    </ng-container>

    <myflow-dynamic-form
      class="w-100"
      [config]="elements"
      (keydown.enter)="onSubmit()"
      #userForm
    ></myflow-dynamic-form>

  </div>
  <div class="side-popup-footer">
    <p class="btn btn-link" (click)="close()">Cancel</p>
    <app-submit-btn [loading]="isLoading" [disabled]="isLoading" (btnClick)="onSubmit()">
      {{ editMode ? 'Save' : 'Create' }}
    </app-submit-btn>
  </div>
</div>

<ng-template #imageCrop>
  <myflow-image-crop *ngIf="imgFile" (closeImgCrop)="closeImgCrop($event)" [file]="imgFile"
                    [id]="id"></myflow-image-crop>
</ng-template>
