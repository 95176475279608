import { AfterViewInit, Component, Input, ViewChild, ElementRef } from '@angular/core';

import { DialogsService } from '../../services/dialogs';
import { InternalSidePopup } from '../../services/side-popup.service';
import { DynamicFormComponent } from '../dynamic-form/containers/dynamic-form/dynamic-form.component';

@Component({
  selector: 'myflow-side-popup',
  templateUrl: './side-popup.component.html',
  styleUrls: [ './side-popup.component.scss' ],
})
export class SidePopupComponent implements AfterViewInit {
  @ViewChild('content') content: ElementRef;
  @ViewChild('form') form: DynamicFormComponent;
  @Input() popup: InternalSidePopup;

  constructor(private dialogsService: DialogsService) {
  }

  ngAfterViewInit(): void {
    this.popup.form = this.form;

    if (this.content && this.content.nativeElement.clientHeight < this.content.nativeElement.scrollHeight) {
      this.content.nativeElement.children[0].classList.add('h-100');
    }
  }

  onSubmit(): void {
    this.form.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.popup.loading.next(true);
    this.popup.emitter.emit(this.form.value);
  }

  onChange(): void {
    this.popup.updated.next(true);
  }

  close(): void {
    if (this.popup.updated.getValue()) {
      this.dialogsService.confirm({ action: 'close' })
        .then(confirm => {
          if (confirm) {
            this.popup.emitter.emit({ closing: true });
          }
        });
    } else {
      this.popup.emitter.emit({ closing: true });
    }
  }
}
