<div class="wrapper">
  <div class="click-catcher" (click)="close()"></div>

  <ng-container *ngTemplateOutlet="popup.custom"></ng-container>

  <div class="side-popup" *ngIf="!popup.custom">

    <div class="side-popup-header">
      <h1>{{ popup.title }}</h1>
      <div class="side-popup-close" (click)="close()">
        <i class="icon-cancel"></i>
      </div>
    </div>

    <div class="side-popup-content form-content" #content>
      <div *ngIf="!popup.fields?.length" class="flex jumbotron justify-content-center w-100">
        Loading...
      </div>
      <myflow-dynamic-form
        [config]="popup.fields"
        (keydown.enter)="onSubmit()"
        (formChange)="onChange()"
        class="w-100"
        #form
      ></myflow-dynamic-form>
    </div>

    <div class="side-popup-footer">
      <div class="footer" *ngIf="popup.fields?.length">
        <button [disabled]=" (popup.loading | async)" type="button" class="btn btn-link link"
                (click)="close()">
          Cancel
        </button>
        <app-submit-btn [loading]="(popup.loading | async)" (btnClick)="onSubmit()">{{ popup.action || 'Save' }}</app-submit-btn>
      </div>
    </div>
  </div>
</div>
