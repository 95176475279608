import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { DynamicFormComponent } from 'app/shared/components/dynamic-form/containers/dynamic-form/dynamic-form.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FieldConfig } from '../components/dynamic-form/models/field-config.interface';

export interface SidePopup {
  title?: string;
  fields?: FieldConfig[];
  action?: string;
  custom?: TemplateRef<any>;
}

export interface InternalSidePopup extends SidePopup {
  loading: BehaviorSubject<boolean>;
  updated: BehaviorSubject<boolean>;
  emitter: EventEmitter<any>;
  form: DynamicFormComponent | null;
}

@Injectable({
  providedIn: 'root',
})
export class SidePopupService {
  popups: InternalSidePopup[] = [];

  get loading$(): Observable<boolean> {
    return this.getCurrent()?.loading ?? of(false);
  }

  get loading(): boolean {
    return this.getCurrent()?.loading.getValue() ?? false;
  }

  set loading(loading: boolean) {
    this.getCurrent()?.loading.next(loading);
  }

  set fields(fields: FieldConfig[]) {
    const current = this.getCurrent();
    if (current) {
      current.fields = fields;

      // Prevent change to fire when initializing fields
      setTimeout(() => current.updated.next(false), 0);
    }
  }

  getCurrent(): InternalSidePopup | undefined {
    return this.popups[this.popups.length - 1];
  }

  open(popup: SidePopup, ignoreCloseEvent: boolean = false): Observable<any> {
    const internalPopup: InternalSidePopup = {
      ...popup,
      loading: new BehaviorSubject<boolean>(false),
      updated: new BehaviorSubject<boolean>(false),
      emitter: new EventEmitter<any>(),
      form: null,
    };
    this.popups.push(internalPopup);

    // Prevent change to fire when initializing fields
    setTimeout(() => internalPopup.updated.next(false), 0);

    let result = internalPopup.emitter.asObservable();

    if (ignoreCloseEvent) {
      result = result.pipe(filter(data => {
        if (data.closing) {
          this.close();
        }

        return !data.closing;
      }));
    }

    return result;
  }

  close(): void {
    this.popups.pop()?.emitter.complete();
  }
}
