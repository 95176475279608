<div class="side-popup slick-footer">
  <div class="side-popup-header">
    <h1>Switch Organization</h1>
    <div class="side-popup-close" (click)="close()">
      <i class="icon-cancel"></i>
    </div>
  </div>
  <div class="side-popup-content">
    <ul class="creator-list">
      <li *ngFor="let org of assignedOrganizations" class="creator-item" [ngClass]="{ 'text-primary': org.id === active }" (click)="switch(org)">
        <div>{{ org.name }}</div>
        <div *ngIf="org.id === active"><i class="icon-check-circle"></i></div>
      </li>
    </ul>
  </div>
  <div class="side-popup-footer">
    <button type="button" class="btn btn-link link" (click)="close()">Cancel</button>
    <app-submit-btn [loading]="loading" (btnClick)="onSwitch()">Switch</app-submit-btn>
  </div>
</div>