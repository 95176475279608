<div class="side-popup">
  <div class="side-popup-header">
    <h1>Profile Picture</h1>
  </div>
  <div class="side-popup-content">
    <div myflowDnD (filesChangeEmiter)="fileChangeEvent($event)" [allowedExtensions]="['jpg', 'jpeg', 'png', 'gif']">
      <div *ngIf="error" class="alert alert-danger" (click)="file.click()">
        <p>{{ error }}</p>
        <input type="file" #file style="display: none" (change)="fileChangeEvent($event)"/>
        <button type="button" class="btn btn-primary" (click)="file.click()">Change Image</button>
      </div>
      <image-cropper [imageFile]="imageFileChanged" (imageCropped)="imageCropped($event)"
                      (loadImageFailed)="loadImageFailed()" [aspectRatio]="1 / 1" [resizeToWidth]="170"
                     [imageQuality]="75" format="jpg" width="100%" class="p-0" *ngIf="!error" />
    </div>
  </div>

  <div class="side-popup-footer">
    <p class="btn btn-link" (click)="close()">Close</p>
    <button type="button" class="btn btn-primary" (click)="save()">Save</button>
  </div>
</div>


