import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmService } from '../../services/confirm.service';
import { BehaviorSubject } from 'rxjs';
import { DynamicFormComponent } from '../dynamic-form/containers/dynamic-form/dynamic-form.component';
import { FieldConfig } from '../dynamic-form/models/field-config.interface';

@Component({
  selector: 'myflow-dialog-critical',
  templateUrl: './dialog-critical.component.html',
  styleUrls: [ './dialog-critical.component.scss' ],
})
export class DialogCriticalComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() data = true;
  @Input() description: string;
  @Input() showSpinner: boolean;
  @Input() checks: string[];
  @Input() scheme: FieldConfig[];
  @Input() confirmLabel = 'Confirm';

  @Input('loading') loading$ = new BehaviorSubject(false); // eslint-disable-line
  @ViewChild('dynamicForm') dynamicForm: DynamicFormComponent;

  form: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private confirmService: ConfirmService,
  ) {
  }

  ngOnInit(): void {
    const controls = {};

    if (this.checks) {
      for (let i = 0; i < this.checks.length; i++) {
        controls[`check${i}`] = new UntypedFormControl(null, [ Validators.requiredTrue ]);
      }
    }

    this.form = new UntypedFormGroup(controls);
  }

  onSubmit(): void {
    if (this.dynamicForm.valid && this.showSpinner) {
      this.loading$.next(true);
      this.confirmService.updateModalSpinner(this.dynamicForm.value);
    } else {
      this.activeModal.close(this.data);
    }
  }

  confirm(): void {
    if (this.showSpinner) {
      this.loading$.next(true);
      this.confirmService.updateModalSpinner(this.dynamicForm ? this.dynamicForm.value : true);
    } else {
      this.activeModal.close(this.data);
    }
  }

  close(): void {
    this.activeModal.close(false);
  }

  ngOnDestroy(): void {
    this.confirmService.updateModalSpinner(false);
  }
}
