
<ng-template #message>
  We operate only in ISO certified and FINMA compliant data centers<br *ngIf="!toast">
  in Switzerland. <a href="https://doc.flow.swiss/platform/regions" target="_blank">Learn more about our regions</a>
</ng-template>

<ng-container  *ngIf="showHint">
  <div class="hint-outer" *ngIf="!toast">
    <p class="hint-inner">
      <ng-container *ngTemplateOutlet="message" />
    </p>
  </div>
  <myflow-hint-card *ngIf="toast" wizard="horizontal ? 'horizontal' : 'large'">
    <ng-container *ngTemplateOutlet="message" />
  </myflow-hint-card>
</ng-container>