<div class="side-popup">
  <div class="side-popup-header">
    <h1>Upgrade your Support Plan</h1>

    <div class="side-popup-close" (click)="close()">
      <i class="icon-cancel"></i>
    </div>
  </div>

  <div class="side-popup-content">
    <div class="support-header">
      <h2 class="support-header-title">Upgrade Support Plan</h2>
      <p class="support-header-description">
        Choose a support plan to enhance your support experience. Before you can take advantage of the new support plan, our sales team will review your request and contact you. 
        Learn more about our <a href="https://doc.flow.swiss/platform/pricing/support" target="_blank">Support Plans</a>.
      </p>
    </div>
    <myflow-plan-select [plans]="plans$ | async" [(ngModel)]="selectedPlan" />
  </div>

  <div class="side-popup-footer popup-footer">
    <button type="button" class="btn btn-link link" (click)="close()">Cancel</button>
    <app-submit-btn [loading]="loading$ | async" (btnClick)="requestSelectedPlan()" [disabled]="selectedPlan === undefined">REQUEST</app-submit-btn>
  </div>
</div>
