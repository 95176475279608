import { Component, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'myflow-region-hint',
  templateUrl: './region-hint.component.html',
  styleUrls: [ './region-hint.component.scss' ],
})
export class RegionHintComponent {
  @Input() toast: boolean;
  @Input() horizontal: boolean;

  get showHint(): boolean {
    return environment.brand === 'myflow';
  }
}
