<div class="modal-header">
  <h6 class="modal-title">{{ title }}</h6>
  <div class="wizard-close" (click)="close()">
    <i class="icon icon-cancel"></i>
  </div>
</div>
<div class="modal-body">
  <div #body>
    <ng-content select="[body]"></ng-content>
  </div>
  <ng-container *ngIf="body.childNodes.length === 0 && !scheme">
    <div *ngIf="description" [innerHTML]="description">{{ description }}</div>
    <form [formGroup]="form">
      <div class="form-group" *ngFor="let item of checks; let i = index">
        <input [id]="'check' + i" type="checkbox" [formControlName]="'check' + i">
        <label [for]="'check' + i">{{ item }}</label>
      </div>
    </form>
  </ng-container>
  <myflow-dynamic-form
    *ngIf="body.childNodes.length === 0 && scheme"
    [config]="scheme"
    (keydown.enter)="onSubmit()"
    #dynamicForm
  ></myflow-dynamic-form>
</div>

<div #footer>
  <ng-content select="[footer]"></ng-content>
</div>

<div class="modal-footer" *ngIf="footer.childNodes.length === 0">
  <button [disabled]="(loading$ | async)" type="button" class="btn btn-link link" (click)="close()">
    Cancel
  </button>

  <app-submit-btn [loading]="(loading$ | async)"
                  [disabled]="((scheme && !dynamicForm?.valid) || (!scheme && !form.valid)) && (loading$ | async) === false"
                  (btnClick)="confirm()">
    {{confirmLabel}}
  </app-submit-btn>
</div>
