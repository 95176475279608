import { CdkStepperModule } from '@angular/cdk/stepper';

import { CommonModule } from '@angular/common';

import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatMomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';

import { RouterModule } from '@angular/router';
/** Dependency Lib Modules */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DownloadService } from 'app/shared/services/download.service';
import { ImageCropperModule } from 'ngx-image-cropper';
/** Components */
import {
    AmountSelectComponent,
    AutoRechargeComponent,
    ButtonsMenuComponent,
    CheckboxComponent,
    CodeFieldsComponent,
    CreditCardsEditComponent,
    DetailsComponent,
    DialogComponent,
    DialogFullComponent,
    DialogConfirmComponent,
    DialogCriticalComponent,
    DialogWizardComponent,
    DragAndDropComponent,
    DynamicFormModule,
    EditProfileComponent,
    ImageCropComponent,
    LoadingPageComponent,
    ModuleHeaderComponent,
    MyFlowToastComponent,
    OsIconComponent,
    OsListComponent,
    PriceLabelComponent,
    ProductCardsComponent,
    QrCodeComponent,
    RangeAmountComponent,
    RegionSelectComponent,
    RegionHintComponent,
    SearchBtnComponent,
    SidebarComponent,
    StorageGraphComponent,
    SubmitBtnComponent,
    TableCardComponent,
    TableComponent,
    TableJumbotronComponent,
    TableNgrxComponent,
    TablePaginationComponent,
    TablePlaceholdersComponent,
    ToastPendingComponent,
    ToggleComponent,
    TableTruncatedCellComponent,
    TableVideoJumbotronComponent,
    PlanSelectComponent,
    CardDropdownSelectComponent,
    ClipboardCardComponent,
    HintCardComponent,
    InfoPanelComponent,
    LoadingSpinnerComponent,
} from './components';
// fix Circular dependency
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { SidePopupComponent } from './components/side-popup/side-popup.component';
/** Custom Directives */
import { DirectivesModule } from './directives/directives.module';
/** Custom Pipes */
import { PipesModule } from './pipes/pipes.module';

import { CreditCardsComponent } from './components/credit-cards/credit-cards.component';
import { OranizationSwitcherComponent } from './components/oranization-switcher/oranization-switcher.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { OverviewGridCardComponent } from './components/overview-grid-card/overview-grid-card.component';
import { StatusDotComponent } from './components/status-dot/status-dot.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PasswordDisplayComponent } from './components/password-display/password-display.component';
import { NotificationIconComponent } from './components/notification-icon/notification-icon.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { MatIconModule } from '@angular/material/icon';
import { SupportPlanToggleComponent } from './components/support-plan-toggle/support-plan-toggle.component';
import { EditSupportPlanComponent } from './components/edit-support-plan/edit-support-plan.component';
import { SupportPlanSidebarComponent } from './components/support-plan-sidebar/support-plan-sidebar.component';
import { FancySeparatorComponent } from './components/fancy-separator/fancy-separator.component';
import { SideBySideSelectComponent } from './components/side-by-side-select/side-by-side-select.component';
const dateFormat = 'YYYY-MM-DD';

@NgModule({
    declarations: [
        ButtonsMenuComponent,
        CreditCardsComponent,
        CreditCardsEditComponent,
        DialogComponent,
        DialogFullComponent,
        DialogConfirmComponent,
        DialogWizardComponent,
        DragAndDropComponent,
        EditProfileComponent,
        ImageCropComponent,
        RangeAmountComponent,
        TableComponent,
        MyFlowToastComponent,
        LoadingPageComponent,
        SubmitBtnComponent,
        OsListComponent,
        ModuleHeaderComponent,
        DetailsComponent,
        TableJumbotronComponent,
        DetailsComponent,
        TableCardComponent,
        TablePlaceholdersComponent,
        TablePaginationComponent,
        OsIconComponent,
        AutoRechargeComponent,
        ToggleComponent,
        SidePopupComponent,
        InfoTooltipComponent,
        ToastPendingComponent,
        CheckboxComponent,
        QrCodeComponent,
        PriceLabelComponent,
        SidebarComponent,
        ProductCardsComponent,
        DialogCriticalComponent,
        AmountSelectComponent,
        CodeFieldsComponent,
        SearchBtnComponent,
        OranizationSwitcherComponent,
        TableNgrxComponent,
        OverviewGridCardComponent,
        StatusDotComponent,
        TableTruncatedCellComponent,
        ExpansionPanelComponent,
        PasswordDisplayComponent,
        NotificationIconComponent,
        MonthPickerComponent,
        StorageGraphComponent,
        SupportPlanToggleComponent,
        SupportPlanSidebarComponent,
        EditSupportPlanComponent,
        FancySeparatorComponent,
        SideBySideSelectComponent,
        RegionSelectComponent,
        RegionHintComponent,
        TableVideoJumbotronComponent,
        PlanSelectComponent,
        CardDropdownSelectComponent,
        ClipboardCardComponent,
        HintCardComponent,
        InfoPanelComponent,
        LoadingSpinnerComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        NgSelectModule,
        ImageCropperModule,
        CdkStepperModule,
        MatSidenavModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatPaginatorModule,
        MatCardModule,
        MatTabsModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatExpansionModule,
        NgApexchartsModule,
        /** Pipes */
        PipesModule,
        /** Directives */
        DirectivesModule,
        /** Components */
        DynamicFormModule,
        MatIconModule,
    ],
    exports: [
        ButtonsMenuComponent,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgSelectModule,
        ImageCropperModule,
        CdkStepperModule,
        MatSidenavModule,
        MatTableModule,
        MatMenuModule,
        MatPaginatorModule,
        MatCardModule,
        MatTabsModule,
        MatSortModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatExpansionModule,
        /** Pipes */
        PipesModule,
        /** Directives */
        DirectivesModule,
        /** Components */
        CreditCardsComponent,
        CreditCardsEditComponent,
        DialogComponent,
        DialogFullComponent,
        DialogConfirmComponent,
        DialogWizardComponent,
        DragAndDropComponent,
        DynamicFormModule,
        EditProfileComponent,
        ImageCropComponent,
        RangeAmountComponent,
        TableJumbotronComponent,
        TablePlaceholdersComponent,
        TableComponent,
        MyFlowToastComponent,
        LoadingPageComponent,
        SubmitBtnComponent,
        OsIconComponent,
        OsListComponent,
        ModuleHeaderComponent,
        TableCardComponent,
        TablePaginationComponent,
        DetailsComponent,
        AutoRechargeComponent,
        ToggleComponent,
        SidePopupComponent,
        ToastPendingComponent,
        CheckboxComponent,
        QrCodeComponent,
        PriceLabelComponent,
        SidebarComponent,
        ProductCardsComponent,
        DialogCriticalComponent,
        AmountSelectComponent,
        InfoTooltipComponent,
        CodeFieldsComponent,
        SearchBtnComponent,
        OranizationSwitcherComponent,
        TableNgrxComponent,
        OverviewGridCardComponent,
        StatusDotComponent,
        TableTruncatedCellComponent,
        ExpansionPanelComponent,
        PasswordDisplayComponent,
        NotificationIconComponent,
        MonthPickerComponent,
        StorageGraphComponent,
        SupportPlanToggleComponent,
        SupportPlanSidebarComponent,
        EditSupportPlanComponent,
        FancySeparatorComponent,
        SideBySideSelectComponent,
        RegionSelectComponent,
        RegionHintComponent,
        TableVideoJumbotronComponent,
        PlanSelectComponent,
        CardDropdownSelectComponent,
        ClipboardCardComponent,
        HintCardComponent,
        InfoPanelComponent,
        LoadingSpinnerComponent,
    ],
    providers: [
        MatDatepickerModule,
        DownloadService,
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ] },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: dateFormat,
                },
                display: {
                    dateInput: dateFormat,
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: dateFormat,
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
        { provide: LOCALE_ID, useValue: 'en-GB' },
    ],
})
export class SharedModule {
}
