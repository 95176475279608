import { Component, Input } from '@angular/core';

@Component({
  selector: 'myflow-hint-card',
  templateUrl: './hint-card.component.html',
  styleUrls: [ './hint-card.component.scss' ],
})
export class HintCardComponent {
  @Input() wizard: null | 'large' | 'summary' | 'horizontal' = null;
  @Input() fixPosition: boolean = false;
}
